/* 404 redirect to homepage
 */

import React from "react"
import { navigate } from "gatsby"
import TabLink from "../components/tab-link"
import AccentedButton from "../components/accented-button"
import getUserContent from "../../user-content"

export default function NotFoundPage(props) {
  const BusinessIdentifiers = getUserContent().BusinessIdentifiers
  return (
    <div id="404-page" role="main">
      <BusinessIdentifiers />
      <h2>404: Page not found</h2>
      <p>The specified page "{props.path}" doesn't exist.</p>
      <p>
        If you think this is an error, please&nbsp;
        <TabLink to="/contact">contact us</TabLink>.
        We're eager to improve our website.
      </p>
      <AccentedButton
        onClick={() => navigate("/")}
        data-qa={"HomeButton"}
      >
        Homepage
      </AccentedButton>
    </div>
  )
}
